.typewriter-container {
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 10;
  text-align: left;
}

.typewriter-wrapper {
  position: relative;
  display: inline-block;
}

.typewriter-text {
  color: white;
  font-size: 5rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.2;
}

.typewriter-subtext {
  color: white;
  font-size: 2.1rem;
  font-weight: normal;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin: 10px 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.2;
}

.typewriter-cursor {
  position: absolute;
  right: -3px;
  bottom: 0;
  width: 3px;
  height: 2.1rem;
  background-color: white;
  opacity: 0;
}

@keyframes blink-caret {
  from, to { background-color: transparent }
  50% { background-color: white; }
} 