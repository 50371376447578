.flapper-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
  padding: 20px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
}

.flapper-unit {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.flapper-label {
  color: #fff;
  font-size: 12px;
  letter-spacing: 1px;
  opacity: 0.8;
  text-transform: uppercase;
}

.flapper-separator {
  color: #fff;
  font-size: 36px;
  margin: 0 5px;
  opacity: 0.8;
  align-self: flex-start;
  margin-top: 10px;
}

/* Override Flapper.js default styles */
.flapper .digit {
  background-color: #1a1a1a !important;
  border: 1px solid #333 !important;
}

.flapper .top,
.flapper .bottom {
  color: #fff !important;
  background-color: #1a1a1a !important;
}

.flapper .top {
  border-bottom: 1px solid #333 !important;
}

/* Customize display sizes */
.display {
  transform: scale(0.8);
  margin: 0 -5px;
} 