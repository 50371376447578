.app-container {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: black;
  overflow: hidden;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-player {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.controls {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 8px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.video-container:hover .controls {
  opacity: 1;
}

.control-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.control-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.App {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
} 